<script setup lang="ts">
import { colorPalette as colors } from '@/utils/enums'
import { useProjectStore } from '@/stores/projectStore'
import { computed, onMounted, ref, watch } from 'vue'
import { useCategoryStore } from '@/stores/categoryStore'
import { useRouter } from 'vue-router'
import { getHashedId } from '@/utils/hash.helper'
import { type ProjectStatus, type UpdateProjectPayload } from '@/types/project.type'
import { _ElConfirm, _ElMessage } from '@/utils/element-plus-wrapper'
import { downloadFile } from '@/utils/file.helper'
import { CircleCheckFilled, Edit } from '@element-plus/icons-vue'
import { Download } from '@element-plus/icons-vue'
import { useUserStore } from '@/stores/userStore'
import { UserRole } from '@/types/user.type'

const CATALOG_DATA_ERROR = '設定不備があるようです。管理者にご連絡ください。'

const props = defineProps<{
  projectId: number
}>()

const router = useRouter()
const projectStore = useProjectStore()
const projectData = computed(() => projectStore.current_project)
const categoryStore = useCategoryStore()
categoryStore.clear_current_top_category()

function init() {
  if (!projectData.value) {
    setTimeout(() => {
      init()
    }, 500)
    return
  }
  if (projectStore.current_top_category) {
    categoryStore.get_top_category_detail(projectStore.current_top_category.id).catch(() => {})
  } else {
    throw new Error('invalid route')
  }
}
onMounted(() => {
  init()
})
watch(
  () => projectData.value,
  () => {
    init()
  }
)

// status
function toggleStatus(currentStatus: ProjectStatus) {
  const isOpened = currentStatus === 'open'
  _ElConfirm(`案件を${isOpened ? 'クローズ' : 'オープン'}しますか?`, '案件のステータス変更確認', {
    confirmButtonText: 'はい',
    cancelButtonText: 'いいえ',
    type: 'warning'
  })
    .then(() => {
      if (!projectData.value) {
        _ElMessage({ type: 'error', message: '案件情報が取得できませんでした' })
        return
      }
      const payload: UpdateProjectPayload = {
        id: projectData.value.id,
        status: isOpened ? 'closed' : 'open'
      }
      projectStore
        .update_project(payload)
        .then((res) => {
          const message =
            res.status === 'closed' ? '案件をクローズしました' : '案件をオープンしました'
          _ElMessage({ type: 'success', message })
        })
        .catch(() => {
          _ElMessage({ type: 'error', message: '案件のステータスを変更できませんでした' })
        })
    })
    .catch(() => {})
}

// situation
const topCategoryLabel = computed(() => projectStore.current_top_category?.name || 'Loading...')
const objectiveLabel = computed(() => {
  return projectStore.current_objective?.title || 'Loading...'
})
const isSkipObjective = computed(() => projectStore.should_skip_objective)
const asisPng = computed(() => {
  return categoryStore.current_asis_image?.asisPng
})
const tobePng = computed(() => {
  return categoryStore.current_tobe_images_candidates.find(
    (e) => e.id == projectStore.current_objective?.targetTobeImageId
  )?.tobePng
})
const asisPptx = computed(() => {
  return categoryStore.current_asis_image?.asisPptx
})
const tobePptx = computed(() => {
  return categoryStore.current_tobe_images_candidates.find(
    (e) => e.id == projectStore.current_objective?.targetTobeImageId
  )?.tobePptx
})
const downloadingId = ref(-1)
const isAsisImageDialog = ref(false)
const isTobeImageDialog = ref(false)

function getAsisImageUrl(fileId: number) {
  const topCategoryId = projectStore.current_top_category?.id || -1
  if (topCategoryId == -1) {
    return undefined
  }
  return categoryStore.get_asis_image_url(topCategoryId, fileId, 'png')
}
function getTobeImageUrl(fileId: number) {
  const objectiveId = projectStore.current_objective?.id || -1
  const tobeImageId =
    categoryStore.current_objective_candidates.find((e) => e.id == objectiveId)
      ?.targetTobeImageId || -1
  if (tobeImageId == -1) {
    return undefined
  }
  return categoryStore.get_tobe_image_url(tobeImageId, fileId, 'png')
}
async function downloadAsisPptx() {
  if (downloadingId.value !== -1) {
    return
  }
  if (!asisPptx.value || !projectData.value?.topCategoryId) {
    _ElMessage({ type: 'error', message: 'ファイルが見つかりません' })
    return
  }
  try {
    downloadingId.value = asisPptx.value.id
    const { data, filename } = await categoryStore.download_asis_image(
      projectData.value.topCategoryId,
      asisPptx.value.id,
      asisPptx.value.fileExtension
    )
    downloadFile(data, filename)
  } catch (error) {
    console.error('ファイルダウンロードエラー:', error)
  } finally {
    downloadingId.value = -1
  }
}
async function downloadTobePptx() {
  if (downloadingId.value !== -1) {
    return
  }
  if (!tobePptx.value) {
    _ElMessage({ type: 'error', message: 'ファイルが見つかりません' })
    return
  }
  try {
    downloadingId.value = tobePptx.value.id
    const { data, filename } = await categoryStore.download_tobe_image(
      tobePptx.value.tobeImageId,
      tobePptx.value.id,
      tobePptx.value.fileExtension
    )
    downloadFile(data, filename)
  } catch (error) {
    console.error('ファイルダウンロードエラー:', error)
  } finally {
    downloadingId.value = -1
  }
}

// actionItems
const actionLabel = computed(() => {
  return projectStore.current_tobe_item?.description || 'Loading...'
})
const projectItems = computed(() => projectData.value?.projectItems || [])
function openProjectItem(id: number) {
  router.push(`/projects?id=${getHashedId(props.projectId)}&itemId=${getHashedId(id)}`)
}

// edit
const isEditMode = ref(false)
const isSubmitting = ref(false)
const updateProjectDataPayload = ref<Pick<UpdateProjectPayload, 'id' | 'title'>>({
  id: -1,
  title: ''
})
function openUpdateDialog() {
  if (!projectData.value) {
    _ElMessage({ type: 'error', message: '案件情報が取得できませんでした' })
    return
  }
  isEditMode.value = true
  updateProjectDataPayload.value = {
    id: projectData.value.id,
    title: projectData.value.title
  }
}
function onClickSave() {
  if (!projectData.value) {
    _ElMessage({ type: 'error', message: '案件情報が取得できませんでした' })
    return
  }
  isSubmitting.value = true
  projectStore
    .update_project(updateProjectDataPayload.value)
    .then(() => {
      _ElMessage({ type: 'success', message: 'タイトルを変更しました' })
      isEditMode.value = false
    })
    .catch(() => {
      _ElMessage({ type: 'error', message: 'タイトルの変更に失敗しました' })
    })
    .finally(() => {
      isSubmitting.value = false
    })
}

// auth
const userStore = useUserStore()
const isAllowedToEditProject = computed(() => {
  if (!projectData.value || !userStore.current_user) {
    return false
  }
  if (userStore.current_user.role === UserRole.admin) {
    return true
  }

  return projectData.value?.userId === userStore.current_user.id
})
</script>

<template>
  <template v-if="true">
    <el-dialog :close-on-press-escape="false" v-model="isEditMode">
      <template #header>案件の編集</template>
      <el-form :v-model="updateProjectDataPayload" label-position="left" label-width="80px">
        <el-form-item label="タイトル">
          <el-input v-model="updateProjectDataPayload.title" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="isEditMode = false">キャンセル</el-button>
        <el-button type="primary" @click="onClickSave">保存</el-button>
      </template>
    </el-dialog>
  </template>
  <template v-if="asisPng">
    <el-dialog
      :close-on-press-escape="false"
      v-model="isAsisImageDialog"
      top="5vh"
      width="80%"
      class="ix-dialog"
    >
      <template #header>
        <div class="image-modal-title-container">
          <div class="image-modal-title">プレビュー</div>
          <el-popover
            placement="top-start"
            :width="200"
            trigger="hover"
            content="ダウンロード"
            v-if="asisPptx"
          >
            <template #reference>
              <el-button :icon="Download" style="font-size: 18px" link @click="downloadAsisPptx" />
            </template>
          </el-popover>
        </div>
      </template>
      <div class="image-dialog-img-wrapper">
        <div class="image">
          <img :src="getAsisImageUrl(asisPng.id)" crossOrigin="use-credentials" />
        </div>
      </div>
    </el-dialog>
  </template>
  <template v-if="tobePng">
    <el-dialog
      :close-on-press-escape="false"
      v-model="isTobeImageDialog"
      top="5vh"
      width="80%"
      class="ix-dialog"
    >
      <template #header>
        <div class="image-modal-title-container">
          <div class="image-modal-title">プレビュー</div>
          <el-popover
            placement="top-start"
            :width="200"
            trigger="hover"
            content="ダウンロード"
            v-if="tobePptx"
          >
            <template #reference>
              <el-button :icon="Download" style="font-size: 18px" link @click="downloadTobePptx" />
            </template>
          </el-popover>
        </div>
      </template>
      <div class="image-dialog-img-wrapper">
        <div class="image">
          <img :src="getTobeImageUrl(tobePng.id)" crossOrigin="use-credentials" />
        </div>
      </div>
    </el-dialog>
  </template>
  <div class="project-detail">
    <div v-if="!projectData" class="loading">Loading...</div>
    <template v-else>
      <div class="subheader">
        <div
          class="title-wrapper clickable"
          v-if="isAllowedToEditProject"
          @click="openUpdateDialog"
        >
          <div class="title-text">
            {{ (projectData.status === 'closed' ? '[CLOSED] ' : '') + projectData.title }}
          </div>
          <Edit style="width: 16px; height: 16px; margin-top: 2px" />
        </div>
        <div class="action-buttons" v-if="isAllowedToEditProject">
          <el-button @click="toggleStatus(projectData.status)">
            {{ projectData.status === 'open' ? 'クローズする' : 'オープンする' }}
          </el-button>
        </div>
        <div class="title-wrapper" v-else>
          <div class="title-text">
            {{ (projectData.status === 'closed' ? '[CLOSED] ' : '') + projectData.title }}
          </div>
        </div>
      </div>
      <div class="situation-tags">
        <div class="situation-tag">
          <div class="tag-name">業種・部門</div>
          <div class="tag-label">{{ topCategoryLabel }}</div>
        </div>
        <div v-if="!isSkipObjective" class="situation-tag">
          <div class="tag-name">主目的</div>
          <div class="tag-label">{{ objectiveLabel }}</div>
        </div>
      </div>
      <div class="situation-images-wrapper">
        <div v-if="asisPng" class="situation-image clickable" @click="isAsisImageDialog = true">
          <img :src="getAsisImageUrl(asisPng.id)" crossOrigin="use-credentials" />
        </div>
        <div v-else class="situation-image placeholder">{{ CATALOG_DATA_ERROR }}</div>
        <div v-if="tobePng" class="situation-image clickable" @click="isTobeImageDialog = true">
          <img :src="getTobeImageUrl(tobePng.id)" crossOrigin="use-credentials" />
        </div>
        <div v-else class="situation-image placeholder">{{ CATALOG_DATA_ERROR }}</div>
      </div>
      <el-divider />
      <div class="action-tag">
        <div class="tag-name-container">
          <div class="tag-name">取り組む施策</div>
        </div>
        <div class="tag-label">{{ actionLabel }}</div>
      </div>

      <div class="instruction">
        <CircleCheckFilled style="width: 1em; height: 1em; margin-left: 4px" />
        <div class="instruction-text">
          各ソリューションについて、選定ポイント / 概算見積もり事例 / 比較表をまとめました。
        </div>
      </div>
      <div class="project-items">
        <div
          v-for="item in projectItems"
          :key="item.id"
          class="project-item"
          @click="openProjectItem(item.id)"
        >
          {{ item.title }}
        </div>
      </div>
    </template>
  </div>
</template>
<style scoped>
.project-detail {
  padding: 0 0 240px 0;
  height: 100%;
  overflow-y: scroll;
}
.loading {
  padding: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: v-bind('colors.text.disabled');
}
.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  line-height: 32px;
  margin-bottom: 4px;
}
.title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 14px;
  max-width: 80%;
  height: 32px;
  line-height: 32px;
  .title-text {
    font-size: 18px;
    font-weight: bold;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: v-bind('colors.text.base');
  }
}
.clickable {
  cursor: pointer;
}
.action-buttons {
  display: flex;
  justify-content: flex-end;
}
.situation-tags {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.situation-tag {
  display: flex;
  gap: 0;
  align-items: center;
  height: 28px;
  line-height: 28px;
}
.tag-name {
  font-size: 12px;
  font-weight: bold;
  color: v-bind('colors.text.white');
  background-color: v-bind('colors.bg.gray05');
  padding: 0 8px;
}
.tag-label {
  font-size: 14px;
  font-weight: bold;
  padding-left: 8px;
  padding-right: 20px;
}
.situation-images-wrapper {
  margin-top: 16px;
  display: flex;
  width: 100%;
  gap: 8px;
  justify-content: space-between;
}
.situation-image {
  width: 100%;
  height: auto;
  border: 1px solid v-bind('colors.border.base');
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 16 / 9;
    color: v-bind('colors.text.disabled');
    font-weight: bold;
    background-color: v-bind('colors.bg.gray05');
  }
}
.clickable {
  cursor: pointer;
}
.action-tag {
  display: flex;
  gap: 0;
  align-items: center;
  min-height: 28px;
  height: auto;
  .tag-name-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    color: v-bind('colors.text.white');
    background-color: v-bind('colors.bg.gray05');
    padding: 0 8px;
    .tag-name {
      font-size: 12px;
      font-weight: bold;
    }
  }
  .tag-label {
    font-size: 14px;
    font-weight: bold;
    padding: 4px 20px 4px 8px;
    line-height: 20px;
  }
}
.instruction {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 32px;
  font-size: 20px;
  color: v-bind('colors.utility.green');
  .instruction-text {
    font-size: 14px;
    font-weight: bold;
  }
}

.project-items {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
}
.project-item {
  padding: 16px 24px;
  border: 3px solid v-bind('colors.border.base');
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  color: v-bind('colors.text.base');
  /* background-color: v-bind('colors.bg.white'); */
  cursor: pointer;
}
/* Image dialog */
.image-modal-title-container {
  display: flex;
  align-items: center;
}
.image-modal-title {
  margin-right: 10px;
}
.image {
  height: auto;
  border: 1px solid v-bind('colors.border.base');
}
.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
