<script setup lang="ts">
import { computed, ref } from 'vue'
import { colorPalette as colors } from '@/utils/enums'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import { useRouter } from 'vue-router'
import { useProductStore } from '@/stores/productStore'
import type { GetProductsQuery } from '@/types/product.type'
import { Warning } from '@element-plus/icons-vue'
import { useUserStore } from '@/stores/userStore'

const router = useRouter()
const productStore = useProductStore()
const userStore = useUserStore()

const isAdmin = computed(() => userStore.get_current_user.role === 'admin')

const getProductsQuery = ref<GetProductsQuery>({
  page: 1,
  limit: 100
})
productStore.clear_products()
productStore.get_products(getProductsQuery.value)
const products = computed(() => productStore.products)
const isSubmitting = ref(false)

// create
const isCreateMode = ref(false)
const dialogCreateData = ref({
  name: ''
})
function openCreateDialog() {
  dialogCreateData.value = {
    name: ''
  }
  isCreateMode.value = true
}
function submitCreate() {
  if (!dialogCreateData.value.name.length) {
    _ElMessage({ type: 'error', message: '名前を入力してください' })
    return
  }
  isSubmitting.value = true
  productStore
    .create_product(dialogCreateData.value.name)
    .then(() => {
      isCreateMode.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}

// transit
function openDetailPage(productId: number) {
  router.push('/catalogs?menu=productList&resourceId=' + productId)
}
</script>

<template>
  <div id="product-list">
    <!-- dialog -->
    <template v-if="true">
      <el-dialog
        :close-on-press-escape="false"
        v-model="isCreateMode"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>商材の新規登録</template>
        <el-form
          :model="dialogCreateData"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <el-form-item label="名前">
            <el-input v-model="dialogCreateData.name" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span>
            <el-button link @click="isCreateMode = false">閉じる</el-button>
            <el-button :loading="isSubmitting" @click="submitCreate">保存する</el-button>
          </span>
        </template>
      </el-dialog>
    </template>
    <!-- template -->
    <div class="subheader">
      <div class="subheader-title">商材</div>
      <div v-if="isAdmin" class="subheader-action">
        <el-button @click="openCreateDialog()">新規登録</el-button>
      </div>
    </div>
    <div class="ix-table">
      <div
        class="ix-table-row clickable"
        v-for="(row, i) in products"
        :key="row.id"
        @click="openDetailPage(row.id)"
      >
        <div class="ix-table-column th">#{{ i + 1 }}</div>
        <div class="ix-table-column">{{ row.name }}</div>
        <div class="ix-table-column end">
          <el-tag v-for="s in row.solutions" :key="s.id" type="info">{{ s.title }}</el-tag>
          <el-popover
            placement="top-start"
            :width="200"
            trigger="hover"
            content="ソリューションに登録されていません"
          >
            <template #reference>
              <Warning
                v-if="!row.solutions.length"
                style="
                  width: 16px;
                  height: 16px;
                  margin-top: 2px;
                  margin-right: 8px;
                  color: #d46f72;
                "
              />
            </template>
          </el-popover>
        </div>
      </div>
      <div v-if="!products.length" class="row-placeholder">データ未登録です</div>
    </div>
  </div>
</template>
<style scoped>
#product-list {
  padding-right: 24px;
  height: 100%;
  color: v-bind('colors.text.base');
}
.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.subheader-title {
  font-size: 16px;
  font-weight: bold;
  height: 32px;
  line-height: 32px;
}
.ix-table-row {
  gap: 20px;
  padding-left: 16px;
}
.row-placeholder {
  padding-left: 16px;
  font-size: 12px;
  color: v-bind('colors.text.disabled');
}
</style>
