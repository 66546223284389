import { defineStore } from 'pinia'
import type { _AxiosError } from './api'
import axios from './api'
import { useUserStore } from './userStore'
import type { User } from '@/types/user.type'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import type { TokenValidationPayload } from '@/types/auth.type'

export const useAuthStore = defineStore('authStore', {
  state: () => ({}),
  getters: {
    is_loggedin(): boolean {
      const userStore = useUserStore()
      return !!userStore.current_user
    }
  },
  actions: {
    authedHello(json: { redirect: string }) {
      return new Promise<User>((resolve, reject) => {
        // 401 => refreshの際にredirect設定するためにinterceptorに情報を渡す
        axios
          .get('/auth/hello', { params: json })
          .then((r: { data: { user: User } }) => {
            const userStore = useUserStore()
            userStore.set_current_user(r.data.user)
            resolve(r.data.user)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    login(json: { email: string; password: string }) {
      return new Promise<User>((resolve, reject) => {
        axios
          .post('/auth/login', json)
          .then((res) => {
            const userStore = useUserStore()
            userStore.set_current_user(res.data)
            resolve(res.data)
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            _ElMessage({ type: 'error', message: 'ログインに失敗しました' })
            reject(err)
          })
      })
    },
    logout() {
      const userStore = useUserStore()
      userStore.clear_current_user()
      return new Promise<void>((resolve, reject) => {
        axios
          .post('/auth/logout')
          .then(() => {
            resolve()
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    validateToken(json: TokenValidationPayload) {
      return new Promise<void>((resolve, reject) => {
        axios
          .post('/auth/validateToken', json)
          .then((res: { data: User }) => {
            const userStore = useUserStore()
            userStore.set_current_user(res.data)
            resolve()
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    },
    forgetPassword(email: string) {
      return new Promise<void>((resolve, reject) => {
        axios
          .post('/forget-password', { email })
          .then(() => {
            resolve()
          })
          .catch((err: _AxiosError) => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
})
