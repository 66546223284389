<script setup lang="ts">
import { colorPalette as colors, PageTypes } from '@/utils/enums'
import CommonHeader from '../components/CommonHeader.vue'
import ProjectDetail from '../components/projects/ProjectDetail.vue'
import ProjectFlow from '../components/projects/ProjectFlow.vue'
import ProjectItem from '../components/projects/ProjectItem.vue'
import WorkSheetDetail from '../components/projects/WorkSheetDetail.vue'
import { useRouter } from 'vue-router'
import { useProjectStore } from '@/stores/projectStore'
import {
  ProjectOwnershipQuery,
  ProjectStatusQuery,
  type GetProjectsQuery
} from '@/types/project.type'
import { computed, ref, watch } from 'vue'
import { useCaseItemStore } from '@/stores/caseItemStore'
import {
  CaseItemStatusQuery,
  CaseItemTypeQuery,
  type CaseItemDetail,
  type GetCaseItemsQuery,
  type CaseItemMessageDetail
} from '@/types/caseItem.type'
import { extractIdFromHash, getHashedId } from '@/utils/hash.helper'
import { Filter, Back } from '@element-plus/icons-vue'
import { _ElConfirm, _ElMessage } from '@/utils/element-plus-wrapper'
import { CaseItemTypeLabel } from '@/types/caseItem.type'
import getLatestCaseItemMessage from '@/utils/get-latest-caseItem-message'
import { marked } from '@/utils/text.helper'
import { useUserStore } from '@/stores/userStore'
import { useAnnouncementStore } from '@/stores/announcementStore'
import { datetimeFormatter } from '@/utils/date.formatter'
import {
  type GetAnnouncementsQuery,
  type AnnouncementType,
  AnnouncementStatus,
  type UpdateAnnouncementPayload,
  type CreateAnnouncementPayload
} from '@/types/announcement.type'

const props = defineProps<{
  projectIdHash?: string
  projectItemIdHash?: string
  caseItemIdHash?: string
  pageType?: PageTypes
}>()

// load current project
const projectId = computed(() => {
  const id = (() => {
    try {
      return props.projectIdHash ? extractIdFromHash(props.projectIdHash) : -1
    } catch (e) {
      return -1
    }
  })()
  return id
})
const projectItemId = computed(() => {
  const id = (() => {
    try {
      return props.projectItemIdHash ? extractIdFromHash(props.projectItemIdHash) : -1
    } catch (e) {
      return -1
    }
  })()
  return id
})
const caseItemId = computed(() => {
  const id = (() => {
    try {
      return props.caseItemIdHash ? extractIdFromHash(props.caseItemIdHash) : -1
    } catch (e) {
      return -1
    }
  })()
  return id
})
watch(projectId, () => {
  loadCurrentProject()
})
function loadCurrentProject() {
  projectStore.clear_current_project()
  if (projectId.value !== -1) {
    projectStore.get_project_detail(projectId.value).catch(() => {})
  }
}
const targetComponent = computed(() => {
  if (projectItemId.value !== -1) {
    if (props.pageType == 'worksheet') {
      return WorkSheetDetail
    }
    return ProjectItem
  }
  return projectStore.current_project?.confirmedAt ? ProjectDetail : ProjectFlow
})

// init
const router = useRouter()
const projectStore = useProjectStore()
const caseItemStore = useCaseItemStore()
const userStore = useUserStore()
const announcementStore = useAnnouncementStore()
const projectsQuery = ref<GetProjectsQuery>({
  page: 1,
  limit: 50,
  userIds: [],
  tobeItemIds: [],
  topCategoryIds: [],
  objectiveIds: [],
  solutionIds: [],
  ownershipQuery: ProjectOwnershipQuery.onlySelf,
  status: ProjectStatusQuery.all
})
const caseItemsQuery = ref<GetCaseItemsQuery>({
  page: 1,
  limit: 20,
  onlyUnreplied: false,
  caseItemStatus: CaseItemStatusQuery.open,
  caseItemType: CaseItemTypeQuery.all
})
const announcementQuery = ref<GetAnnouncementsQuery>({
  page: 1,
  limit: 10
})
const isAdmin = computed(() => userStore.current_user?.role === 'admin')
projectStore.clear_projects()
caseItemStore.clear_case_items()
projectStore.get_projects(projectsQuery.value)
caseItemStore.get_case_items(caseItemsQuery.value)
announcementStore.get_announcements(announcementQuery.value)
loadCurrentProject()

const caseItems = computed(() => caseItemStore.case_items)
const projects = computed(() => {
  return projectsQuery.value.status === 'all'
    ? projectStore.projects
    : projectStore.projects.filter((x) => x.status === 'open')
})

// sidebar
const isProjectFilterActive = computed(() => {
  return projectsQuery.value.status !== 'all'
})
const filterLabel = computed(() => {
  return isProjectFilterActive.value ? 'OPEN' : 'ALL'
})
function toggleFilter() {
  if (projectsQuery.value.status == 'all') {
    projectsQuery.value.status = 'open'
  } else {
    projectsQuery.value.status = 'all'
  }
}
function openProject(id: number) {
  router.push(`/projects?id=${getHashedId(id)}`)
}

// create project
const isCreateMode = ref(false)
const dialogCreateData = ref<{ title: string }>({ title: '' })
const isSubmitting = ref(false)
function openCreateDialog() {
  dialogCreateData.value = { title: '' }
  isCreateMode.value = true
}
function submitCreate() {
  if (dialogCreateData.value.title == '') {
    _ElMessage({ type: 'error', message: 'タイトルを入力してください' })
    return
  }
  isSubmitting.value = true
  projectStore.create_project(dialogCreateData.value).then((p) => {
    isSubmitting.value = false
    isCreateMode.value = false
    openProject(p.id)
  })
}

// breadcrumb
const previousUrl = computed(() => {
  if (projectId.value === -1 || projectItemId.value === -1) {
    return null
  }
  if (props.pageType == 'worksheet') {
    return `/projects?id=${getHashedId(projectId.value)}&itemId=${getHashedId(projectItemId.value)}`
  } else {
    return `/projects?id=${getHashedId(projectId.value)}`
  }
})
function transit(url: string) {
  router.push(url)
}

// caseItems
const filterCaseItemsLabel = computed(() => {
  return caseItemsQuery.value.caseItemStatus === CaseItemStatusQuery.open ? 'OPEN' : 'ALL'
})
const isCaseItemsFilterActive = computed(() => {
  return caseItemsQuery.value.caseItemStatus !== CaseItemStatusQuery.open
})
function openCaseItemDetail(item: CaseItemDetail) {
  router.push(
    `/projects?id=${getHashedId(item.projectItem.projectId)}&itemId=${getHashedId(item.projectItemId)}&cId=${getHashedId(item.id)}`
  )
}
function toggleCaseItemsFilter() {
  if (caseItemsQuery.value.caseItemStatus == CaseItemStatusQuery.open) {
    caseItemsQuery.value.caseItemStatus = CaseItemStatusQuery.all
  } else {
    caseItemsQuery.value.caseItemStatus = CaseItemStatusQuery.open
  }
  caseItemStore.get_case_items(caseItemsQuery.value)
}
function parseLatestMessageDataHtml(messages: CaseItemMessageDetail[]) {
  return (
    getLatestCaseItemMessage(messages)?.date ||
    `<span style='color: ${colors.text.lighter};'>no data</span>`
  )
}
function parseLatestMessageHtml(messages: CaseItemMessageDetail[]) {
  return (
    getLatestCaseItemMessage(messages)?.text ||
    `<span style='color: ${colors.text.lighter};'>メッセージが削除されました。</span>`
  )
}

// announcement
const announcements = computed<AnnouncementType[]>(() => {
  const array = [...announcementStore.announcements].filter((x) => {
    if (isAdmin.value) return true
    return x.status === 'published'
  })
  return showAllAnnouncements.value ? array : array.slice(0, 3)
})
const showAnnouncementDetailMode = ref(false)
const showAllAnnouncements = ref(false)
const addAnnouncementMode = ref(false)
const editAnnouncementMode = ref(false)
const warning = '一度公開された後は、編集、削除、または公開ステータスの変更ができなくなります。'
const defaultNewAnnouncementData: CreateAnnouncementPayload = {
  title: '',
  content: '',
  status: AnnouncementStatus.draft
}
const newAnnouncementData = ref(defaultNewAnnouncementData)
const editingAnnouncementData = ref<UpdateAnnouncementPayload>({
  id: -1,
  ...defaultNewAnnouncementData
})
const currentAnnouncementData = ref<AnnouncementType | null>(null)
function formatDate(createdAt: Date, updatedAt: Date | undefined) {
  const date = updatedAt ?? createdAt
  return datetimeFormatter(date)
}
function openAnnouncementDialog(announcementData: AnnouncementType) {
  currentAnnouncementData.value = { ...announcementData }
  showAnnouncementDetailMode.value = true
  editAnnouncementMode.value = false
}
function startEditAnnouncement(announcementData: AnnouncementType) {
  editingAnnouncementData.value = { ...announcementData }
  editAnnouncementMode.value = true
}
function parseContentHtml(content: string) {
  let text = content

  text = text.replace(/\n/g, '<br>')
  return marked.parse(text)
}
function openAddAnnouncementDialog() {
  newAnnouncementData.value = { ...defaultNewAnnouncementData }
  addAnnouncementMode.value = true
}
function onAddAnnouncement() {
  if (newAnnouncementData.value.title == '' || newAnnouncementData.value.content == '') {
    _ElMessage({ type: 'error', message: 'タイトルと内容を入力してください' })
    return
  }
  isSubmitting.value = true
  announcementStore
    .create_announcement(newAnnouncementData.value)
    .then(() => {
      addAnnouncementMode.value = false
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
function onEditAnnouncement() {
  if (editingAnnouncementData.value.id === -1) {
    throw new Error('invalid announcement id')
  }
  if (editingAnnouncementData.value.title === '' || editingAnnouncementData.value.content === '') {
    _ElMessage({ type: 'error', message: 'タイトルと内容を入力してください' })
    return
  }
  isSubmitting.value = true
  announcementStore
    .update_announcement(editingAnnouncementData.value)
    .then((res) => {
      editAnnouncementMode.value = false
      currentAnnouncementData.value = res
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
function confirmDeleteAnnouncement(id: number) {
  _ElConfirm('お知らせを削除しますか？', '削除', {
    confirmButtonText: '削除',
    type: 'warning'
  }).then(() => {
    isSubmitting.value = true
    announcementStore
      .delete_announcement(id)
      .then(() => {
        showAnnouncementDetailMode.value = false
      })
      .finally(() => {
        isSubmitting.value = false
      })
  })
}
const announcementTotalPages = computed(() => {
  let num = 1
  if (announcementStore.announcement_stats) {
    num = announcementStore.announcement_stats.totalPages
  }
  return num
})
const announcementCurrentPage = computed({
  get: () => announcementQuery.value.page,
  set: (val) => (announcementQuery.value.page = val)
})
function changeAnnouncementPage(v: number) {
  announcementQuery.value.page = v
  announcementStore.get_announcements(announcementQuery.value)
}
// pagination
const totalPages = computed(() => {
  let num = 1
  if (caseItemStore.case_items_stats) {
    num = caseItemStore.case_items_stats.totalPages
  }
  return num
})
const currentPage = computed({
  get: () => caseItemsQuery.value.page,
  set: (val) => (caseItemsQuery.value.page = val)
})
function changePage(v: number) {
  caseItemsQuery.value.page = v
  caseItemStore.get_case_items(caseItemsQuery.value)
}
</script>
<template>
  <el-container>
    <el-header height="48px">
      <CommonHeader current-menu="projects" />
    </el-header>
    <el-container>
      <!-- dialog -->
      <el-dialog
        :close-on-press-escape="false"
        v-model="isCreateMode"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>新規案件の登録</template>
        <el-form
          :model="dialogCreateData"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <el-form-item label="タイトル">
            <el-input v-model="dialogCreateData.title" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span>
            <el-button link @click="isCreateMode = false">閉じる</el-button>
            <el-button :loading="isSubmitting" @click="submitCreate">次へ</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
        :close-on-press-escape="false"
        v-model="addAnnouncementMode"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>お知らせ追加</template>
        <el-form
          :model="newAnnouncementData"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <el-form-item label="タイトル">
            <el-input v-model="newAnnouncementData.title" />
          </el-form-item>
          <el-form-item label="内容">
            <el-input
              input-style="height: 200px"
              type="textarea"
              v-model="newAnnouncementData.content"
            />
          </el-form-item>
          <el-form-item label="公開ステータス">
            <el-radio-group v-model="newAnnouncementData.status">
              <el-radio :value="AnnouncementStatus.draft">非公開</el-radio>
              <el-radio :value="AnnouncementStatus.published">公開</el-radio>
            </el-radio-group>
          </el-form-item>
          <template v-if="newAnnouncementData.status === AnnouncementStatus.published">
            <div class="warning">
              {{ warning }}
            </div>
          </template>
        </el-form>
        <template #footer>
          <span>
            <el-button link @click="addAnnouncementMode = false">閉じる</el-button>
            <el-button :loading="isSubmitting" @click="onAddAnnouncement">登録</el-button>
          </span>
        </template>
      </el-dialog>
      <template v-if="currentAnnouncementData">
        <el-dialog
          :close-on-press-escape="false"
          v-model="showAnnouncementDetailMode"
          top="10vh"
          width="50%"
          class="ix-dialog"
        >
          <template #header>お知らせ詳細</template>
          <div class="announcement-detail">
            <template v-if="editAnnouncementMode">
              <el-form label-width="120px" label-position="left">
                <el-form-item label="タイトル">
                  <el-input v-model="editingAnnouncementData.title" />
                </el-form-item>
                <el-form-item label="内容">
                  <el-input
                    input-style="height: 200px"
                    type="textarea"
                    v-model="editingAnnouncementData.content"
                  />
                </el-form-item>
                <el-form-item label="公開ステータス">
                  <el-radio-group v-model="editingAnnouncementData.status">
                    <el-radio :value="AnnouncementStatus.draft">未公開</el-radio>
                    <el-radio :value="AnnouncementStatus.published">公開</el-radio>
                  </el-radio-group>
                </el-form-item>
                <template v-if="editingAnnouncementData.status === AnnouncementStatus.published">
                  <div class="warning">
                    {{ warning }}
                  </div>
                </template>
              </el-form>
            </template>
            <template v-else>
              <div class="meta-info-wrapper margin-bottom flex-gap">
                <div class="date">
                  {{
                    formatDate(currentAnnouncementData.createdAt, currentAnnouncementData.updatedAt)
                  }}
                </div>
                <span
                  class="status"
                  v-if="currentAnnouncementData.status === AnnouncementStatus.draft"
                  >未公開</span
                >
              </div>
              <h2 class="title">{{ currentAnnouncementData.title }}</h2>

              <div class="content" v-html="parseContentHtml(currentAnnouncementData.content)" />
            </template>
          </div>
          <template #footer>
            <div class="el-dialog-footer_both_side">
              <span v-if="currentAnnouncementData.status === AnnouncementStatus.draft">
                <el-button
                  link
                  class="danger"
                  @click="confirmDeleteAnnouncement(currentAnnouncementData.id)"
                  >削除する</el-button
                >
              </span>
              <span v-else></span>
              <span>
                <template v-if="editAnnouncementMode">
                  <el-button link @click="editAnnouncementMode = false">キャンセル</el-button>
                  <el-button :loading="isSubmitting" @click="onEditAnnouncement">保存</el-button>
                </template>
                <template v-else>
                  <el-button link @click="showAnnouncementDetailMode = false">閉じる</el-button>
                  <el-button
                    v-if="currentAnnouncementData.status === AnnouncementStatus.draft"
                    @click="startEditAnnouncement(currentAnnouncementData)"
                  >
                    編集する
                  </el-button>
                </template>
              </span>
            </div>
          </template>
        </el-dialog>
      </template>
      <!-- template -->
      <el-aside width="280px">
        <div id="sidebar">
          <div class="new-project-btn" @click="openCreateDialog()">新規案件の登録</div>
          <template v-if="!userStore.isPresentationMode">
            <div class="local-menu-header">
              <div class="local-menu-title">My Projects</div>
              <div
                class="local-menu-filter"
                :class="{ active: isProjectFilterActive }"
                @click="toggleFilter()"
              >
                <Filter style="width: 24px; height: 24px; padding: 4px" />
                {{ filterLabel }}
              </div>
            </div>
            <div
              class="project-item"
              :class="{ active: p.id == projectId, closed: p.status == 'closed' }"
              v-for="p in projects"
              :key="p.id"
              @click="openProject(p.id)"
            >
              {{ (p.status === 'closed' ? '[CLOSED] ' : '') + p.title }}
            </div>
            <div v-if="!projects.length" class="empty-projects">案件がありません</div>
          </template>
        </div>
      </el-aside>
      <el-main>
        <div v-if="previousUrl" class="ix-breadcrumb-navi">
          <div class="ix-breadcrumb-navi-item" @click="transit(previousUrl)">
            <Back style="width: 16px; height: 16px; margin-right: 4px" />戻る
          </div>
        </div>
        <div v-if="projectId == -1" class="project-home">
          <div class="title-container">
            <span class="announcement-heading"> お知らせ </span>
            <el-button link @click="openAddAnnouncementDialog" v-if="isAdmin">+追加</el-button>
          </div>
          <div class="announcement-container">
            <div v-if="announcements.length > 0">
              <div v-for="info in announcements" :key="info.id">
                <div class="announcement">
                  <div class="meta-info-wrapper">
                    <span class="date">{{ formatDate(info.createdAt, info.updatedAt) }}</span>
                    <span v-if="info.status === 'draft'" class="status">未公開</span>
                    <span class="title" v-on:click="openAnnouncementDialog(info)">
                      {{ info.title }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="announcement-pagination-footer" v-if="showAllAnnouncements">
                <el-pagination
                  class="pagination"
                  layout="prev, pager, next"
                  :hide-on-single-page="true"
                  :page-count="announcementTotalPages"
                  :current-page="announcementCurrentPage"
                  @current-change="changeAnnouncementPage"
                />
              </div>
            </div>
            <div v-else>お知らせはありません</div>
          </div>
          <template v-if="announcementStore.announcements.length > 3">
            <el-button link @click="showAllAnnouncements = true" v-if="!showAllAnnouncements">
              もっと見る
            </el-button>
            <el-button link @click="showAllAnnouncements = false" v-else>閉じる</el-button>
          </template>
          <template v-if="!userStore.isPresentationMode">
            <div class="case-items-title">
              <div class="list-title">やりとり中の相談・見積もり依頼の一覧</div>
              <div
                class="local-menu-filter"
                :class="{ active: isCaseItemsFilterActive }"
                @click="toggleCaseItemsFilter()"
              >
                <Filter style="width: 24px; height: 24px; padding: 4px" />
                {{ filterCaseItemsLabel }}
              </div>
            </div>
            <table class="case-items">
              <tr
                v-for="item in caseItems"
                :key="item.id"
                class="case-item-row"
                :class="{ closed: item.caseItemStatus == 'closed' }"
                @click="openCaseItemDetail(item)"
              >
                <td class="case-item-type">{{ CaseItemTypeLabel[item.caseItemType] }}</td>
                <td class="case-item-latest-message-date">
                  <span class="date-text" v-html="parseLatestMessageDataHtml(item.messages)" />
                  |
                </td>
                <td class="case-item-project">
                  {{ item.projectItem.project.title }} ({{ item.projectItem.title }})
                </td>
                <td
                  class="case-item-latest-message-text"
                  v-html="parseLatestMessageHtml(item.messages)"
                />
              </tr>
              <tr v-if="!caseItems.length" class="empty-case-items">
                ... No data. 案件情報をご登録いただいた後、相談や見積もり依頼を送ることができます。
              </tr>
            </table>
          </template>
          <div class="pagination-footer">
            <el-pagination
              class="pagination"
              layout="prev, pager, next"
              :hide-on-single-page="true"
              :page-count="totalPages"
              :current-page="currentPage"
              @current-change="changePage"
            />
          </div>
        </div>
        <component
          v-else
          :is="targetComponent"
          :projectId="projectId"
          :projectItemId="projectItemId"
          :caseItemId="caseItemId"
        />
      </el-main>
    </el-container>
  </el-container>
</template>
<style scoped>
.el-container {
  background-color: v-bind('colors.bg.gray01');
  height: 100%;
  overflow: hidden;
}
.el-header {
  padding: 0;
}
.el-main {
  padding: 18px 20px 8px 20px;
}
.el-aside {
  padding: 0 0 16px;
}
/* sidebar */
#sidebar {
  padding-top: 16px;
  height: 100%;
  border-right: 2px solid v-bind('colors.border.base');
}
.new-project-btn {
  margin: 0 auto;
  width: calc(100% - 32px);
  height: 40px;
  line-height: 40px;
  color: v-bind('colors.text.white');
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  background-color: v-bind('colors.service.darkBlue');
  cursor: pointer;
}
.local-menu-header {
  display: flex;
  justify-content: space-between;
  height: 24px;
  line-height: 24px;
  padding-left: 16px;
  margin-top: 24px;
  margin-bottom: 4px;
}
.local-menu-title {
  font-size: 14px;
  font-weight: bold;
  color: v-bind('colors.text.lighter');
}
.local-menu-filter {
  display: flex;
  align-items: center;
  width: 64px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  height: 24px;
  line-height: 24px;
}
.local-menu-filter.active {
  color: v-bind('colors.service.darkBlue');
}
.project-item {
  padding-left: 16px;
  cursor: pointer;
  height: 32px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: bold;
  color: v-bind('colors.text.base');
  &:hover,
  &.active {
    color: v-bind('colors.text.white');
    background-color: v-bind('colors.service.darkBlue');
  }
  &.closed {
    opacity: 0.5;
  }
}
.empty-projects {
  padding: 8px 16px;
  font-size: 14px;
  color: v-bind('colors.text.disabled');
}
/* home */
.title-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.announcement-heading {
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
  color: v-bind('colors.text.base');
}
.announcement-container {
  border: 1px solid v-bind('colors.border.base');
  padding: 10px;
  min-height: 80px;
  margin-bottom: 5px;
  width: 900px;
}
.announcement-detail {
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .date {
    font-size: 13px;
    color: v-bind('colors.text.lighter');
  }

  .content {
    border-radius: 4px;
    margin-top: 5px;
    border: 1px solid v-bind('colors.border.base');
    padding: 15px 20px;
    background-color: v-bind('colors.bg.gray02');
    min-height: 200px;
  }
}
.meta-info-wrapper {
  display: flex;
  align-items: center;
}
.flex-gap {
  gap: 10px;
}

.status {
  display: inline-block;
  padding: 4px 8px;
  margin-right: 5px;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  background-color: v-bind('colors.bg.gray05');
  color: v-bind('colors.text.white');
}
.margin-bottom {
  margin-bottom: 10px;
}

.announcement {
  padding: 4px;

  .date {
    display: inline-block;
    padding: 4px 8px;
    margin-right: 5px;
    flex-shrink: 0;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }

  .title {
    text-decoration: underline;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80%;
    display: inline-block;
  }
}
.warning {
  margin-left: 120px;
  margin-top: -10px;
  color: v-bind('colors.utility.red');
}
.announcement-pagination-footer {
  margin-top: 10px;
}
/* caseItems */
.case-items-title {
  display: flex;
  align-items: center;
  margin-top: 10px;
  height: 40px;
  line-height: 40px;
  .list-title {
    margin-right: 20px;
    font-size: 16px;
    font-weight: bold;
    color: v-bind('colors.text.base');
  }
}
.case-items {
  width: calc(100% - 20px);
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
  .empty-case-items {
    padding-left: 4px;
    font-size: 12px;
    font-weight: bold;
    color: v-bind('colors.text.disabled');
  }
}
.case-item-row {
  height: 24px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  cursor: pointer;
  &.closed {
    opacity: 0.5;
  }
  .case-item-type {
    width: 120px;
    flex-shrink: 0;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    background-color: v-bind('colors.utility.yellow');
    color: v-bind('colors.text.white');
  }
  .case-item-latest-message-date {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .date-text {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    width: 140px;
  }
  .case-item-project {
    font-size: 12px;
    font-weight: bold;
    flex-shrink: 0;
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .case-item-latest-message-text {
    padding-left: 8px;
    font-size: 14px;
    min-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
