type UserPreferences = {
  presentationMode?: boolean
}

export type User = {
  id: number
  userGroupId: number
  userGroup: UserGroup
  email: string
  name: string
  role: UserRole
  isEmailVerified: boolean
  requiredUpdatePassword: boolean
  createdAt: Date
  updatedAt: Date
  deactivatedAt?: Date
  preferences?: UserPreferences
}

export const UserRole = {
  normal: 'normal',
  supervisor: 'supervisor',
  admin: 'admin'
} as const
export type UserRole = keyof typeof UserRole

export type UserGroup = {
  id: number
  name: string
  createdAt: Date
  updatedAt: Date
}

export type UpdateUserGroupPayload = {
  id: number
  name: string
}

export type GetUsersQuery = {
  userGroupId?: number
  page: number
  limit: number
}

export type GetUserGroupsQuery = {
  page?: number
  limit?: number
}

export type CreateUserPayload = {
  userGroupId: number
  email: string
  name: string
  role: UserRole
}

export type UpdateUserPayload = Pick<User, 'id' | 'name'>

export type UpdateUserPreferencesPayload = {
  id: number
  preferences?: UserPreferences
}
