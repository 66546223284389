import axios from './api'
import { defineStore } from 'pinia'
import { _ElMessage } from '@/utils/element-plus-wrapper'
import type {
  BulkUpdateWorksheetCellValuePayload,
  CreateWorksheetCellValuePayload,
  CreateWorksheetColumnPayload,
  GetWorksheetQuery,
  UpdateWorksheetColumnLabelPayload,
  Worksheet,
  WorksheetCellCutData,
  WorksheetCellValue,
  WorksheetColumnDetail,
  WorksheetColumnLabel,
  WorksheetColumnLabelDetail,
  WorksheetColumnWithSolution,
  WorksheetData,
  WorksheetProductCutData
} from '@/types/worksheet.type'
import type { Solution } from '@/types/solution.type'
import { extractFilename } from '@/utils/file.helper'

interface State {
  worksheet: Worksheet | null
  labels: WorksheetColumnLabelDetail[]
  multipleSolutionColumns: { solution: Solution; data: WorksheetColumnDetail[] }[]
  singleProductCellValues: WorksheetCellValue[]
  cellCutColumns: WorksheetColumnWithSolution[]
}

export const useWorksheetStore = defineStore('worksheetStore', {
  state: (): State => ({
    worksheet: null,
    labels: [],
    multipleSolutionColumns: [],
    singleProductCellValues: [],
    cellCutColumns: []
  }),
  getters: {
    worksheetData:
      (state) =>
      (isImportantColumnsOnly: boolean = false, targetProductIds?: number[]): WorksheetData => {
        if (!state.worksheet) {
          return []
        }
        const sheet = state.worksheet
        const targetProducts = targetProductIds?.length
          ? sheet.products.filter((p) => targetProductIds.includes(p.id))
          : sheet.products
        const data: WorksheetData = []
        state.worksheet.columns
          .sort((a, b) => a.sortNumber - b.sortNumber)
          .forEach((column) => {
            const cellValues: (WorksheetCellValue | null)[] = []
            targetProducts.forEach((product) => {
              const cell = sheet.cellValues.find(
                (cell) => cell.productId === product.id && cell.worksheetColumnId === column.id
              )
              if (cell) {
                cellValues.push(cell)
              } else {
                cellValues.push(null)
                console.log('cell not found')
              }
            })
            data.push({
              rowKey: column,
              rowValues: cellValues
            })
          })
        return isImportantColumnsOnly ? data.filter((row) => row.rowKey.isImportant) : data
      },
    worksheetProductCutData(state): WorksheetProductCutData {
      const res: WorksheetProductCutData = []
      state.multipleSolutionColumns.forEach((x) => {
        const data: { column: WorksheetColumnDetail; cellValue: WorksheetCellValue | null }[] = []
        x.data
          .sort((a, b) => a.sortNumber - b.sortNumber)
          .forEach((column) => {
            const cellValue = state.singleProductCellValues.find(
              (cell) => cell.worksheetColumnId === column.id
            )
            data.push({ column, cellValue: cellValue || null })
          })
        res.push({ solution: x.solution, data })
      })
      return res
    },
    worksheetCellCutData(state): WorksheetCellCutData {
      return state.cellCutColumns.map((x) => {
        const cellValue =
          state.singleProductCellValues.find((cell) => cell.worksheetColumnId === x.id) || null
        return {
          solution: x.solution,
          column: x,
          cellValue
        }
      })
    }
  },
  actions: {
    clear_worksheet() {
      this.$patch((state) => {
        state.worksheet = null
        state.multipleSolutionColumns = []
        state.singleProductCellValues = []
      })
    },
    get_worksheet(json: GetWorksheetQuery) {
      axios
        .get('/worksheets', { params: json })
        .then((res) => {
          this.$patch((state) => {
            state.worksheet = res.data
          })
        })
        .catch((err) => {
          _ElMessage({ type: 'error', message: err.message })
        })
    },
    get_all_labels() {
      axios
        .get('/worksheets/labels')
        .then((res) => {
          this.$patch((state) => {
            state.labels = res.data
          })
        })
        .catch((err) => {
          _ElMessage({ type: 'error', message: err.message })
        })
    },
    create_worksheet_column_label(name: string) {
      return new Promise<WorksheetColumnLabel>((resolve, reject) => {
        axios
          .post('/worksheets/labels', { name })
          .then((res: { data: WorksheetColumnLabelDetail }) => {
            this.$patch((state) => {
              state.labels.push(res.data)
            })
            resolve(res.data)
          })
          .catch((err) => {
            _ElMessage({ type: 'error', message: '登録に失敗しました' })
            reject(err)
          })
      })
    },
    update_worksheet_column_label(json: UpdateWorksheetColumnLabelPayload) {
      return new Promise<void>((resolve, reject) => {
        axios
          .put(`/worksheets/labels/${json.id}`, { name: json.name })
          .then((res: { data: WorksheetColumnLabel }) => {
            this.$patch((state) => {
              const label = state.labels.find((l) => l.id === json.id)
              if (label) {
                label.name = res.data.name
              }
            })
            _ElMessage({ type: 'success', message: '更新しました' })
            resolve()
          })
          .catch((err) => {
            _ElMessage({ type: 'error', message: '更新に失敗しました' })
            console.log(err)
            reject(err)
          })
      })
    },
    delete_worksheet_column_label(id: number) {
      return new Promise<void>((resolve, reject) => {
        axios
          .delete(`/worksheets/labels/${id}`)
          .then(() => {
            this.$patch((state) => {
              state.labels = state.labels.filter((l) => l.id !== id)
            })
            _ElMessage({ type: 'success', message: '削除しました' })
            resolve()
          })
          .catch((err) => {
            _ElMessage({ type: 'error', message: '削除に失敗しました' })
            console.log(err)
            reject(err)
          })
      })
    },
    create_worksheet_column(payload: CreateWorksheetColumnPayload) {
      return new Promise<WorksheetColumnDetail>((resolve, reject) => {
        axios
          .post('/worksheets/columns', payload)
          .then((res: { data: WorksheetColumnDetail }) => {
            this.$patch((state) => {
              if (state.worksheet) {
                state.worksheet.columns.push(res.data)
              }
            })
            _ElMessage({ type: 'success', message: '追加しました' })
            resolve(res.data)
          })
          .catch((err) => {
            _ElMessage({ type: 'error', message: '追加に失敗しました' })
            reject(err)
          })
      })
    },
    toggle_worksheet_column_importannce(id: number, isImportant: boolean) {
      axios
        .put(`/worksheets/columns/${id}`, { isImportant })
        .then((res: { data: WorksheetColumnDetail }) => {
          this.$patch((state) => {
            if (state.worksheet) {
              const column = state.worksheet.columns.find((c) => c.id === id)
              if (column) {
                column.isImportant = res.data.isImportant
              }
            }
          })
          _ElMessage({ type: 'success', message: '更新しました' })
        })
        .catch((err) => {
          _ElMessage({ type: 'error', message: '更新に失敗しました' })
          console.log(err)
        })
    },
    remove_worksheet_column(id: number) {
      axios
        .delete(`/worksheets/columns/${id}`)
        .then(() => {
          this.$patch((state) => {
            if (state.worksheet) {
              state.worksheet.columns = state.worksheet.columns.filter((c) => c.id !== id)
            }
          })
          _ElMessage({ type: 'success', message: '削除しました' })
        })
        .catch((err) => {
          _ElMessage({ type: 'error', message: '削除に失敗しました' })
          console.log(err)
        })
    },
    create_worksheet_cell_value(payload: CreateWorksheetCellValuePayload) {
      return new Promise<WorksheetCellValue>((resolve, reject) => {
        axios
          .post('/worksheets/cellValues', payload)
          .then((res: { data: WorksheetCellValue }) => {
            this.$patch((state) => {
              if (state.worksheet) {
                state.worksheet.cellValues.push(res.data)
              }
            })
            resolve(res.data)
          })
          .catch((err) => {
            _ElMessage({ type: 'error', message: '処理に失敗しました' })
            reject(err)
          })
      })
    },
    bulk_update_worksheet_cell_values(payload: BulkUpdateWorksheetCellValuePayload) {
      return new Promise<void>((resolve, reject) => {
        axios
          .put('/worksheets/cellValues', payload)
          .then((res: { data: WorksheetCellValue[] }) => {
            this.$patch((state) => {
              if (state.worksheet) {
                state.worksheet.cellValues = state.worksheet.cellValues.map((cell) => {
                  const value = res.data.find((v) => v.id === cell.id)
                  if (value) {
                    return value
                  } else {
                    return cell
                  }
                })
              }
            })
            _ElMessage({ type: 'success', message: '更新しました' })
            resolve()
          })
          .catch((err) => {
            _ElMessage({ type: 'error', message: '更新に失敗しました' })
            console.log(err)
            reject(err)
          })
      })
    },
    get_single_solution_columns(solution: Solution) {
      axios
        .get(`/worksheets/columns`, { params: { solutionId: solution.id } })
        .then((res) => {
          this.$patch((state) => {
            state.multipleSolutionColumns.push({
              solution: solution,
              data: res.data
            })
          })
        })
        .catch((err) => {
          _ElMessage({ type: 'error', message: err.message })
        })
    },
    get_single_product_cell_values(productId: number) {
      axios
        .get(`/worksheets/cellValues`, { params: { productId } })
        .then((res: { data: WorksheetCellValue[] }) => {
          this.$patch((state) => {
            state.singleProductCellValues = res.data
          })
        })
        .catch((err) => {
          _ElMessage({ type: 'error', message: err.message })
        })
    },
    get_columns_by_label_id(labelId: number) {
      axios
        .get(`/worksheets/columns/` + labelId)
        .then((res: { data: WorksheetColumnWithSolution[] }) => {
          this.$patch((state) => {
            state.cellCutColumns = res.data
          })
        })
        .catch((err) => {
          _ElMessage({ type: 'error', message: err.message })
        })
    },
    sort_solution_columns(solutionId: number, columnIdsInOrder: number[]) {
      return new Promise<void>((resolve, reject) => {
        axios
          .post(`/worksheets/sortColumns`, { solutionId, columnIdsInOrder })
          .then((res: { data: WorksheetColumnDetail[] }) => {
            this.$patch((state) => {
              if (state.worksheet) {
                // 全件でsortかけてるので置換でOK
                state.worksheet.columns = [...res.data]
              }
            })
            _ElMessage({ type: 'success', message: '反映しました' })
            resolve()
          })
          .catch((err) => {
            _ElMessage({ type: 'error', message: '反映に失敗しました' })
            console.log(err)
            reject(err)
          })
      })
    },
    download_worksheet(json: GetWorksheetQuery) {
      return new Promise<{ data: Blob; filename: string }>((resolve, reject) => {
        axios
          .get('/worksheets/download-xlsx', { params: json, responseType: 'blob' })
          .then((res) => {
            const filename = extractFilename(res, '比較表.xlsx')
            resolve({ data: res.data, filename })
          })
          .catch((error) => {
            console.error(error)
            _ElMessage({ type: 'error', message: 'ダウンロードに失敗しました' })
            reject(error)
          })
      })
    }
  }
})
