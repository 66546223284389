<script setup lang="ts">
import { computed, ref } from 'vue'
import { colorPalette as colors } from '@/utils/enums'
import { _ElConfirm, _ElMessage } from '@/utils/element-plus-wrapper'
import { useRouter } from 'vue-router'
import { useProductStore } from '@/stores/productStore'
import type { UpdateProductPayload } from '@/types/product.type'
import { useWorksheetStore } from '@/stores/worksheetStore'
import { Warning } from '@element-plus/icons-vue'
import { useUserStore } from '@/stores/userStore'

const props = defineProps<{
  resourceId: string
}>()
const router = useRouter()
const productStore = useProductStore()
const userStore = useUserStore()

const isAdmin = computed(() => userStore.get_current_user.role === 'admin')

productStore.clear_current_product()
const productId = (() => {
  if (props.resourceId == '' || isNaN(Number(props.resourceId))) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    router.push('/catalogs?menu=productList')
    return -1
  } else {
    return Number(props.resourceId)
  }
})()

if (productId !== -1) {
  productStore.get_product_detail(productId)
}
const productData = computed(() => productStore.current_product)
const isSubmitting = ref(false)

// Product
let isUpdateProductDialog = ref(false)
let dialogUpdateProductData = ref<UpdateProductPayload>({
  id: -1,
  name: ''
})
function openUpdateProductDialog() {
  if (!productData.value) {
    return
  }
  dialogUpdateProductData.value = {
    id: productData.value.id,
    name: productData.value.name
  }
  isUpdateProductDialog.value = true
}
function submitUpdateProduct() {
  if (dialogUpdateProductData.value.id === -1) {
    _ElMessage({ type: 'error', message: '不正なリソースIDです' })
    return
  }
  if (!dialogUpdateProductData.value.name.length) {
    _ElMessage({ type: 'error', message: '名前を入力してください' })
    return
  }
  isSubmitting.value = true
  productStore
    .update_product(dialogUpdateProductData.value)
    .then(() => {
      isUpdateProductDialog.value = false
    })
    .catch((e) => {
      console.error(e)
    })
    .finally(() => {
      isSubmitting.value = false
    })
}
function confirmDeleteProduct() {
  if (!productData.value) {
    return
  }
  const target = productData.value
  _ElConfirm('この処理は取り消せません。実行しますか？', `商材の削除`, {
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル'
  })
    .then(() => {
      productStore.delete_product(target.id).then(() => {
        router.push('/catalogs?menu=productList')
      })
    })
    .catch(() => {})
}

// Worksheet for each Solution
const isWorksheetLoading = ref(true)
const worksheetStore = useWorksheetStore()
worksheetStore.clear_worksheet()
loadWorksheetData()
function loadWorksheetData() {
  if (productData.value) {
    productData.value.solutions.forEach((s) => {
      worksheetStore.get_single_solution_columns(s)
    })
    worksheetStore.get_single_product_cell_values(productData.value.id)
    setTimeout(() => {
      isWorksheetLoading.value = false
    }, 500)
  } else {
    setTimeout(loadWorksheetData, 1000)
  }
}
const worksheetData = computed(() => worksheetStore.worksheetProductCutData)
function messageFormatter(text?: string) {
  return text ? text.split('\n').join('<br/>') : 'blank'
}

// transit
function openWorksheet(solutionId: number) {
  router.push(`/catalogs?menu=solutionList&pageType=worksheet&resourceId=${solutionId}`)
}
</script>

<template>
  <div id="product">
    <!-- dialog for Product-->
    <template v-if="true">
      <el-dialog
        :close-on-press-escape="false"
        v-model="isUpdateProductDialog"
        top="10vh"
        width="50%"
        class="ix-dialog"
      >
        <template #header>商材の編集</template>
        <el-form
          :model="dialogUpdateProductData"
          label-position="left"
          label-width="120px"
          @submit.prevent
        >
          <el-form-item label="名前">
            <el-input v-model="dialogUpdateProductData.name" />
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="el-dialog-footer_both_side">
            <span>
              <el-button link class="danger" @click="confirmDeleteProduct()">削除する</el-button>
            </span>
            <span>
              <el-button link @click="isUpdateProductDialog = false">閉じる</el-button>
              <el-button :loading="isSubmitting" @click="submitUpdateProduct">保存する</el-button>
            </span>
          </div>
        </template>
      </el-dialog>
    </template>

    <!-- template -->
    <template v-if="!productData"> Now Loading... </template>
    <template v-else>
      <div class="topheader">
        <div class="topheader-title">{{ productData.name }}</div>
        <div v-if="isAdmin" class="topheader-action">
          <el-button @click="openUpdateProductDialog()">編集する</el-button>
        </div>
      </div>
      <div class="subheader">
        <div class="subheader-title">ソリューション別比較表への掲載情報</div>
      </div>
      <div class="worksheets-wrapper">
        <div
          v-for="worksheet in worksheetData"
          :key="worksheet.solution.id"
          class="worksheet-container"
        >
          <div
            class="worksheet-title-wrapper"
            @click="isAdmin ? openWorksheet(worksheet.solution.id) : undefined"
          >
            <div class="worksheet-title">{{ worksheet.solution.title }}</div>
            <el-button v-if="isAdmin" link>編集する</el-button>
          </div>
          <div class="worksheet">
            <div class="worksheet-row" v-for="row in worksheet.data" :key="row.column.id">
              <div class="worksheet-column th">{{ row.column.worksheetColumnLabel.name }}</div>
              <div class="worksheet-column" v-html="messageFormatter(row.cellValue?.note)" />
            </div>
            <div v-if="!worksheet.data.length" class="worksheet-row placeholder">
              <div class="worksheet-column th">項目なし</div>
              <div class="worksheet-column">データ未登録</div>
            </div>
          </div>
        </div>
        <template v-if="!worksheetData.length">
          <div v-if="!isWorksheetLoading" class="worksheet-placeholder">
            <Warning style="width: 14px; height:" />
            データがありません
          </div>
          <div v-else>Now Loading...</div>
        </template>
      </div>
    </template>
  </div>
</template>
<style scoped>
#product {
  padding-right: 24px;
  height: 100%;
  color: v-bind('colors.text.base');
}
.topheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.topheader-title {
  font-size: 24px;
  font-weight: bold;
}
.subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.subheader-title {
  font-size: 16px;
  font-weight: bold;
  height: 32px;
  line-height: 32px;
}
.worksheets-wrapper {
  display: flex;
  flex-direction: row;
  gap: 14px;
}
.worksheet-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-right: 2px solid v-bind('colors.border.base');
  padding-right: 12px;
}
.worksheet-title-wrapper {
  display: flex;
  justify-content: space-between;
  height: 28px;
  line-height: 28px;
  padding-left: 8px;
  border-bottom: 2px solid v-bind('colors.border.base');
  cursor: pointer;
}
.worksheet-title {
  font-size: 16px;
  font-weight: bold;
}
.worksheet-row {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.worksheet-column.th {
  width: 184px;
  font-weight: bold;
}
.worksheet-column {
  width: 240px;
  padding: 4px 8px;
  font-size: 14px;
  color: v-bind('colors.text.base');
  line-height: 20px;
  border-bottom: 1px solid v-bind('colors.border.base');
}
.worksheet-placeholder {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: normal;
  color: v-bind('colors.utility.red');
}
</style>
