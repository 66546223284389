<script setup lang="ts">
import { colorPalette as colors } from '@/utils/enums'
import CommonHeader from '../components/CommonHeader.vue'
import MyAccount, { type MyAccountEditTarget } from '../components/settings/MyAccount.vue'
import Users from '../components/settings/UserList.vue'
import UserGroups from '../components/settings/UserGroupList.vue'
import AiThreads from '../components/settings/AiThreadList.vue'
import { SettingsMenuItems } from '@/utils/enums'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { useUserStore } from '@/stores/userStore'
import { useAuthStore } from '@/stores/authStore'
import { _ElMessage } from '@/utils/element-plus-wrapper'

interface Props {
  menu?: SettingsMenuItems
  myAccountEdit?: MyAccountEditTarget
}
const props = withDefaults(defineProps<Props>(), {
  menu: 'myAccount',
  myAccountEdit: undefined
})
const userStore = useUserStore()
const authStore = useAuthStore()
const router = useRouter()

const isAdmin = computed(() => {
  return userStore.get_current_user.role == 'admin'
})
function getMenuItemClass(name: SettingsMenuItems): string {
  let str = 'menu-item'
  if (name == props.menu) {
    str += ' current'
  }
  return str
}
const editTargetName = computed(() => {
  switch (props.myAccountEdit) {
    case 'name':
      return 'Name'
    case 'password':
      return 'Password'
    default:
      return ''
  }
})
function transitMenu(name: string): void {
  router.push('/settings?menu=' + name)
}

// logout
function logout(): void {
  authStore
    .logout()
    .then(() => {
      router.push('/login')
    })
    .catch(() => {
      _ElMessage({
        type: 'error',
        message: 'ログアウトに失敗しました'
      })
    })
}
</script>

<template>
  <el-container>
    <el-header height="48px">
      <CommonHeader current-menu="settings" />
    </el-header>
    <el-container>
      <el-aside width="280px">
        <div id="sidebar">
          <div class="local-menu-header">Settings Menu</div>
          <div class="menu-wrapper">
            <div :class="getMenuItemClass('myAccount')" @click="transitMenu('myAccount')">
              {{ SettingsMenuItems['myAccount'] }}
            </div>
          </div>
          <div v-if="isAdmin" class="admin-menu-wrapper">
            <div class="local-menu-header">Admin Menu</div>
            <div class="menu-wrapper">
              <div :class="getMenuItemClass('userGroups')" @click="transitMenu('userGroups')">
                {{ SettingsMenuItems['userGroups'] }}
              </div>
              <div :class="getMenuItemClass('users')" @click="transitMenu('users')">
                {{ SettingsMenuItems['users'] }}
              </div>
              <div :class="getMenuItemClass('aiThreads')" @click="transitMenu('aiThreads')">
                {{ SettingsMenuItems['aiThreads'] }}
              </div>
            </div>
          </div>
          <div class="logout-wrapper">
            <el-divider />
            <div class="menu-item logout" @click="logout">
              <img src="@/assets/logout.svg" alt="logout" />
              ログアウト
            </div>
          </div>
        </div>
      </el-aside>
      <el-main>
        <div id="settings-wrapper">
          <div class="breadcrumb-navi">
            <div class="breadcrumb-navi-item">
              <img
                src="@/assets/ic-settings.png"
                srcset="@/assets/ic-settings@2x.png 2x, @/assets/ic-settings@3x.png 3x"
              />
              <template v-if="SettingsMenuItems[menu] === SettingsMenuItems['aiThreads']">
                {{ SettingsMenuItems['aiThreads'] }}
              </template>
              <template v-else>
                Settings - {{ SettingsMenuItems[menu]
                }}<template v-if="myAccountEdit"> - {{ editTargetName }}変更</template>
              </template>
            </div>
          </div>
          <component :is="menu" v-bind="{ edit: myAccountEdit }" />
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>
<script lang="ts">
export default {
  name: 'SettingsView',
  components: {
    MyAccount,
    Users,
    UserGroups,
    AiThreads
  }
}
</script>
<style scoped>
#app .el-container {
  height: 100%;
  overflow: hidden;
  background: v-bind('colors.bg.gray01');
}
.el-header {
  padding: 0;
}
.el-main {
  padding: 16px 0 8px 12px;
}
.el-aside {
  padding: 16px 0;
}
#sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 2px solid v-bind('colors.border.base');
}
.local-menu-header {
  height: 24px;
  line-height: 24px;
  padding-left: 16px;
  font-size: 14px;
  font-weight: bold;
  color: v-bind('colors.text.lighter');
}
.menu-wrapper {
  margin-top: 8px;
  padding: 0 8px;
}
.menu-item {
  height: 36px;
  line-height: 36px;
  padding-left: 16px;
  font-size: 14px;
  font-weight: bold;
  color: v-bind('colors.text.base');
  cursor: pointer;
  margin-bottom: 2px;
}
.menu-item:hover {
  background-color: v-bind('colors.bg.black');
  color: v-bind('colors.text.white');
}
.menu-item.current {
  background-color: v-bind('colors.bg.black');
  color: v-bind('colors.text.white');
}
.admin-menu-wrapper {
  margin-top: 24px;
  padding: 0;
}
.logout-wrapper {
  margin-top: auto;
}
.logout {
  display: flex;
  align-items: center;
}
.logout img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.logout:hover img {
  filter: brightness(0) invert(1);
}
.breadcrumb-navi-item {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: v-bind('colors.text.base');
}
.breadcrumb-navi-item img {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-right: 4px;
  margin-top: -2px;
}
</style>
